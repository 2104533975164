import React from "react";
import { useHorizontalScroll } from "../../lib/hooks/useHorizontalScroll";
import RoadmapItem from "./RoadmapItem";

const Roadmap = () => {
  const roadmap_items = [
    {
      roadmapTitle: "Q4 2021",
      title: "Concept Development",
      info: [
        "Initial idea for a blockchain-based poker platform, with research and feasibility studies.",
      ],
    },
    {
      roadmapTitle: "Q2 2022",
      title: "Team Formation",
      info: [
        "Assembling the core team of blockchain developers, poker experts, and marketers.",
      ],
    },

    {
      roadmapTitle: "Q4 2022",
      title: "Platform Design",
      info: [
        "Developing platform architecture, UI/UX, and blockchain integration.",
      ],
    },

    {
      roadmapTitle: "Q3 2023",
      title: "Smart Contract Development",
      info: [
        "Building and testing smart contracts for anonymous gameplay and cryptocurrency transactions.",
      ],
    },
    {
      roadmapTitle: "Q4 2024",
      title: "ICO & Token Sale",
      info: [
        "Launching the EVOX Casino token sale to raise funds for final development and marketing.",
      ],
    },
    {
      roadmapTitle: "Q2 2025",
      title: "Final Platform Development",
      info: [
        "Completing all platform features, including security and social tools.",
      ],
    },
    {
      roadmapTitle: "Q4 2025",
      title: "Official Launch",
      info: [
        "EVOX Casino goes live, offering anonymous, blockchain-powered poker to players worldwide.",
      ],
    },
  ];

  const scrollRef = useHorizontalScroll();

  return (
    <section id="roadmap" className="roadmap-area pt-130 pb-130">
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title">OUr Roadmap</span>
              <h2 className="title">
              Here’s a concise roadmap of <span>EVOX Casino’s</span> 
               <br /> journey from concept to full launch
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div
              className="bt-roadmap_x bt-roadmap-scrollbar"
              ref={scrollRef}
              style={{ overflow: "auto" }}
            >
              <div className="bt-roadmap-wrap">
                {roadmap_items.map((x, index) => (
                  <RoadmapItem key={index} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
