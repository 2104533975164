import React, { useState } from "react";
import Countdown from "react-countdown";

const CountDownOne = () => {
  const [countdownDate, setCountdownDate ] = useState(new Date("2024-10-15T00:00:00")); // Initial date set to 15.10.2024

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Switch to countdown till 01.01.2025 when first countdown completes
      if (countdownDate.getTime() === new Date("2024-10-15T00:00:00").getTime()) {
        setCountdownDate(new Date("2025-01-01T00:00:00")); // Set new countdown date
      }
      return (
        <>
          <div className="time-count day">
            <span>{"00"}</span>Days
          </div>
          <div className="time-count hour">
            <span>{"00"}</span>hour
          </div>
          <div className="time-count min">
            <span>{"00"}</span>minute
          </div>
          <div className="time-count sec">
            <span>{"00"}</span>second
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="time-count day">
            <span>{days < 10 ? "0" + days : days}</span>Days
          </div>
          <div className="time-count hour">
            <span>{hours < 10 ? "0" + hours : hours}</span>hour
          </div>
          <div className="time-count min">
            <span>{minutes < 10 ? "0" + minutes : minutes}</span>minute
          </div>
          <div className="time-count sec">
            <span>{seconds < 10 ? "0" + seconds : seconds}</span>second
          </div>
        </>
      );
    }
  };

  return (
    <div className="coming-time">
      <Countdown date={countdownDate} renderer={renderer} />
    </div>
  );
};

export default CountDownOne;
